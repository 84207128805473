.pages {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.pokemonList span {
  text-transform: capitalize;
  text-align: center;
}

button {
  background-color: dimgray;
  border: none;
  color: white;
  border: none;
  padding: 14px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  border-radius: 30px;
  margin: 8px 12px;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
  transition: 300ms;
}
